import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
import logo from '../assets/images/hqz-logo.png';

const Contact = () => {
  return (
    <>
        <SEO title="Contact Us - HQZ" />
        <div className="position-relative min-vh-100 bg-black">
            <Header />
            <div className="container d-flex justify-content-center position-relative align-items-center h-100 py-5">
                <div>
                    <img className="mw-300 mx-auto d-block mb-5" src={logo} alt="" />
                    <div className="text-center text-white">
                        <div className="row row-cols-1 row-cols-md-2 g-5">
                            <div className="col">
                                <h3>Management</h3>
                                <a href="mailto:info@hqz.gg" className="text-white text-decoration-none">info@hqz.gg</a>
                            </div>
                            <div className="col">
                                <h3>Advertising</h3>
                                <a href="mailto:ads@hqz.gg" className="text-white text-decoration-none">ads@hqz.gg</a>
                            </div>
                            <div className="col">
                                <h3>Development</h3>
                                <a href="mailto:support@hqz.gg" className="text-white text-decoration-none">support@hqz.gg</a>
                            </div>
                            <div className="col">
                                <h3>Connected Tv</h3>
                                <a href="mailto:ott@hqz.gg" className="text-white text-decoration-none">ott@hqz.gg</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  );
};

export default Contact;
